import React, { useRef, useEffect } from 'react'
import indexStyles from "./index.module.scss"
export default function Home(props) {
  const welcomeText = useRef()
  const hiText = useRef()
  const firstTimerHider = useRef()
  const mainContainer = useRef()
  let timeoutArray = []
  useEffect(function () {
    if (localStorage.getItem("isNotFirstVisit") === "1") {
      hiText.current.innerHTML = `${props.lang === "en" ? "I integrated from Nextjs to Gatsby.js..." : (props.lang === "jp" ? "お帰り～" : "")}`
      welcomeText.current.innerHTML = `${props.lang === "en" ? "Perhaps I'd make another website..." : (props.lang === "jp" ? "また訪れて来たんだね　(ɔ◔‿◔)ɔ ♥" : "")}`
    } else if(localStorage.getItem("isShownAnimation") === "1"){
      hiText.current.innerHTML = "Hi."
      welcomeText.current.innerHTML = "Welcome to my website :)"
    } else {
      mainContainer.current.style.transform = "translateY(35vh)"
      let fHeaderAnimation = setTimeout(function () {
        hiText.current.classList.add(indexStyles.fheaderFade)
        hiText.current.innerHTML = "Hi."
      }, 200)
      timeoutArray.push(fHeaderAnimation)
      let fwelcomeTextAnimation = setTimeout(function () {
        welcomeText.current.classList.add(indexStyles.fwelcomeTextFade)
        welcomeText.current.innerHTML = "Welcome to my website :)"
      }, 1800)
      timeoutArray.push(fwelcomeTextAnimation)
      let fmainAnimation = setTimeout(function () {
        mainContainer.current.classList.add(indexStyles.Translate)
      }, 3000)
      timeoutArray.push(fmainAnimation)
    }
    return (function () {
      for (let i = 0;i < timeoutArray.length; i++){
        clearTimeout(timeoutArray[i])
      }
    })
  }, [])
  return (
    <div className="container">
      <span id="firstTimerHider" ref={firstTimerHider}></span>
      <main id="main" ref={mainContainer}>
        <h1 id="header" ref={hiText}>{props.lang === "en" ? "I integrated from Nextjs to Gatsby.js..." : (props.lang === "jp" ? "お帰り～" : "")}</h1>
        <p ref={welcomeText}>{props.lang === "en" ? "Perhaps I'd make another website..." : (props.lang === "jp" ? "また訪れて来たんだね　(ɔ◔‿◔)ɔ ♥" : "")}</p>
      </main>
    </div>
  )
}

